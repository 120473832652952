<!-- Btra Botones Diferentes tipos -->

<template>
  <div class="btracombi">
    <v-row no-gutters>

      <!-- Botones Extra -->
      <btraextra
          :btra_permisos="btra_permisos"
          :btra_particular="btra_particular"
          :btra_modulo="btra_modulo"
          @onEvent="event_btraextra"> 

            <template v-slot:btns_extra="{ Sitem }">
              <slot name="btns_extra" :Sitem="Sitem"></slot>
            </template>     
      </btraextra>

      <!-- Botones Mto -->
      <btramto
          :btra_permisos="btra_permisos"
          :btra_particular="btra_particular"
          :btra_modulo="btra_modulo"
          :accion="accion"
          @onEvent="event_btramto">      
      </btramto>

    </v-row>
  </div>
</template>


<script>

  import plugs from "@/common/general_plugs";
  const btramto = () => plugs.groute("btramto.vue", "comp");
  const btraextra = () => plugs.groute("btraextra.vue", "comp");

  export default {
    components: { btramto, btraextra },
    props: {
      btra_permisos: { type: [Array, Object], default: undefined },
      btra_particular: { type: [Object, String], default: undefined },
      btra_modulo: { type: String, default: "" },
      accion: { type: [Number, String], default: "" }
    },

    methods: {

      // gestor de eventos de la btra extra
      event_btraextra(evt) 
      {
        console.log("*** event_btraextra. Emit btracombi: ", evt, " ***");
        this.$emit("onEvent", evt);
      },

      // gestor de eventos de la btra de mto
      event_btramto(evt) 
      {
        console.log("*** event_btramto. Emit btracombi: ", evt, " ***");
        this.$emit("onEvent", evt);
      }
      
    }
  };
</script>
